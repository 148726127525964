<template>
  <div class="wrapper">
    <el-carousel class="slideBox" height="100%" :interval="5000" arrow="never">
      <!-- <el-carousel-item> -->
      <div class="slideBox1" style="background-image: url('/images/order/s_01.jpg')">
        <div class="content animate__animated animate__flipInX">
          <div class="title1">订购查询</div>
          <div class="title2">
            <p style="text-align: center; z-index: 1">
              <span>OR</span>
              <span style="color: rgb(0, 255, 192)">D</span>
              <span>ER</span>
              <span style="margin-left: 8px">QU</span>
              <span style="color: rgb(0, 255, 192)">E</span>
              <span>RY</span>
            </p>
          </div>
        </div>
      </div>
      <!-- </el-carousel-item> -->
    </el-carousel>
    <div class="wrapper-content">
      <div class="row_content">
        <div class="content">
          <div class="searchDiv" ref="searchDiv" :class="{ searchDiv: true, searchDiv1: !showResult, searchDiv2: showResult }">
            <el-form ref="queryForm" :rules="rules" :model="query" label-width="0px">
              <el-form-item label="" prop="phone">
                <el-input placeholder="请输入下单手机号." v-model="query.phone" ref="phoneInput">
                  <el-button slot="append" icon="el-icon-search" class="searchBtn" @click="search">查询</el-button>
                </el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="resultDiv" v-if="showResult" v-loading="orderLoading">
            <el-divider content-position="center"
              >仅展示一年内的历史订单<span v-if="orderList">；共有 {{ orderList.length }} 条记录</span></el-divider
            >
            <el-collapse class="resultColl">
              <el-collapse-item v-for="(order, index) in orderList" :key="index">
                <template slot="title">
                  <div style="margin-right: 10px">{{ index + 1 + (query.page - 1) * query.pageSize }}</div>
                  <div>订单号：{{ order.orderNo }}</div>
                  <el-divider direction="vertical" class="resultTitleDivider"></el-divider>
                  <div>订购时间：{{ order.createTime }}</div>
                  <el-divider direction="vertical" class="resultTitleDivider"></el-divider>
                  <div>共 {{ order.proList.length }} 件商品</div>
                  <el-divider direction="vertical" class="resultTitleDivider"></el-divider>
                  <div>订单状态：{{ order.orderStatusName }}</div>
                </template>
                <div class="buyerInfo">
                  <div>买家信息：{{ order.contactName }} | {{ order.phone }} | {{ order.company }} | {{ order.address }}</div>
                </div>
                <table class="resultTable" cellspacing="0" cellpadding="0">
                  <tr height="40px">
                    <th width="60%">产品名称</th>
                    <th width="50px">数量</th>
                    <th>采购方式</th>
                  </tr>
                  <tr class="tableRecord" v-for="(product, pIndex) in order.proList" :key="pIndex">
                    <td>
                      <div class="tableProName">
                        <div class="left"><img :src="product.proImageUrl" style="width: 100px; height: 100px" /></div>
                        <div class="right" @click="proDetail(product.proInfoId)">
                          <div class="t1">{{ product.proName }}</div>
                          <div class="t2">{{ product.proInfo }}</div>
                        </div>
                      </div>
                    </td>
                    <td>{{ product.orderNum }}</td>
                    <td>{{ order.orderWayName }}</td>
                  </tr>
                </table>
              </el-collapse-item>
            </el-collapse>
            <pagination class="pageInfo" small layout="prev, pager, next" v-show="total > 0" :total="total" :page.sync="query.page" :limit.sync="query.pageSize" @pagination="queryPage" />
          </div>
          <el-dialog :visible.sync="vcodeVisible" :close-on-click-modal="false" custom-class="vcodeDialog" @opened="vcodeOpen" center>
            <div class="info1">短信验证</div>
            <div class="info2">请输入手机尾号{{ query.phone.substring(7) }}收到的短信验证码</div>
            <el-form ref="dataForm" label-width="0px" :inline="true">
              <el-form-item>
                <el-input type="input" maxlength="1" v-model="vcode.num1" ref="vcodeNum1" @input="vcodeInput(1)" />
              </el-form-item>
              <el-form-item>
                <el-input type="input" maxlength="1" v-model="vcode.num2" ref="vcodeNum2" @input="vcodeInput(2)" />
              </el-form-item>
              <el-form-item>
                <el-input type="input" maxlength="1" v-model="vcode.num3" ref="vcodeNum3" @input="vcodeInput(3)" />
              </el-form-item>
              <el-form-item>
                <el-input type="input" maxlength="1" v-model="vcode.num4" ref="vcodeNum4" @input="vcodeInput(4)" />
              </el-form-item>
              <el-form-item>
                <el-button type="success" plain size="medium" class="vcodeBtn" :disabled="vcodeClick > 0" @click="vcodeReget">
                  <template v-if="vcodeClick > 0">( {{ vcodeClick }}s )</template>重新获取
                </el-button>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button icon="el-icon-mouse" size="medium" @click="vcodeSubmit"> 提交 </el-button>
            </div>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script scoped>
import Pagination from "@/components/Pagination";
import http from "@/api/front";
import { MsgSuccess } from "@/utils/message";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      orderLoading: true,
      showResult: false,
      vcodeVisible: false,
      vcode: {},
      total: 0,
      query: {
        phone: "",
        vcode: null,
        smsNo: "S01",
        page: 1,
        pageSize: 10,
      },
      orderList: [],
      vcodeClick: 0,
      vcodeClickInterval: null,
      rules: {
        phone: [{ validator: this.$rules.rulePhone, trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.$refs.phoneInput.focus();
    if (this.$route.query.phone) {
      this.query.phone = this.$route.query.phone;
    }
  },
  beforeDestroy() {
    this.vcodeClickTimerStop();
  },
  methods: {
    search() {
      if (!this.query.phone) {
        this.$refs.phoneInput.focus();
        return;
      } else {
        this.$refs["queryForm"].validate((valid) => {
          if (valid) {
            //交烟发送Auth
            http.queryOrderAuth(this.query, (res) => {
              if (res.success) {
                let auth = res.data;
                if (auth) {
                  this.query.page = 1;
                  this.queryPage();
                } else {
                  this.vcode = {};
                  this.getVcode();
                }
              }
            });
          } else {
            this.$refs.phoneInput.focus();
          }
        });
      }
    },
    getVcode() {
      this.$refs["queryForm"].validate((valid) => {
        if (valid) {
          this.vcodeVisible = true;
          http.smsSend(this.query, (res) => {
            if (res.success) {
              this.vcodeClickTimerStart();
            }
          });
        }
      });
    },
    vcodeOpen() {
      this.$refs.vcodeNum1.focus();
    },
    vcodeInput(num) {
      if (num == 1) {
        this.$refs.vcodeNum2.focus();
      } else if (num == 2) {
        this.$refs.vcodeNum3.focus();
      } else if (num == 3) {
        this.$refs.vcodeNum4.focus();
      }
    },
    vcodeReget() {
      this.vcode = {};
      this.$refs.vcodeNum1.focus();
      http.smsSend(this.query, (res) => {
        if (res.success) {
          this.vcodeClickTimerStart();
        }
      });
    },
    vcodeClickTimer() {
      this.vcodeClick--;
      if (this.vcodeClick < 1) {
        this.vcodeClickTimerStop();
      }
    },
    vcodeClickTimerStart() {
      this.vcodeClickTimerStop();
      this.vcodeClick = 60;
      this.vcodeClickInterval = setInterval(this.vcodeClickTimer, 1000);
    },
    vcodeClickTimerStop() {
      if (this.vcodeClickInterval) {
        clearInterval(this.vcodeClickInterval);
      }
    },
    vcodeSubmit() {
      this.query.vcode = this.vcode.num1 + "" + this.vcode.num2 + "" + this.vcode.num3 + "" + this.vcode.num4;
      this.query.page = 1;
      this.queryPage();
    },
    queryPage() {
      this.showResult = true;
      this.orderLoading = true;
      http.queryOrderInfoPage(this.query, (res) => {
        if (res.success) {
          this.total = res.data.count;
          this.orderList = res.data.records;
          this.vcodeVisible = false;
        }
        this.orderLoading = false;
      });
    },
    proDetail(id) {
      let routeUrl = this.$router.resolve({
        path: "/products/info",
        query: { id: id },
      });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
/*slideBox 开始*/
.slideBox {
  width: 100%;
  height: 450px;
  overflow: hidden;
  z-index: 1;
}

.slideBox1 {
  width: 100%;
  height: 100%;
  background-position: 0px 0px;
  background-size: cover;
  z-index: 1;
  .content {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    text-align: left;
    letter-spacing: 3px;
    @media screen and (max-width: 1200px) {
      .header-info {
        width: 100%;
      }
    }

    .title1 {
      color: white;
      font-size: 34px;
      margin-top: 200px;
      font-family: "Source Han Sans", Geneva, sans-serif;
      text-align: center;
      font-weight: bold;
      // letter-spacing: 0.4em;
    }
    .title2 {
      color: white;
      font-size: 14pt;
      margin-top: 20px;
      font-family: 微软雅黑;
      line-height: 1.5;
      letter-spacing: 0.7em;
      font-family: "Arial Black";
    }
  }
}
.el-input {
  width: 100%;
}
.searchDiv1 {
  margin: 80px auto 200px auto;
}
.searchDiv2 {
  margin: 80px auto 40px auto;
}
.searchDiv {
  width: 60%;
  text-align: center;
  .el-button {
    background-color: #31b57e;
    border-radius: 0px 4px 4px 0;
    border: 1px solid #619ca1;
    color: white;
  }
  /deep/ .el-input__inner {
    border: 1px solid #c4dbdd;
  }
}
.resultDiv {
  width: 100%;
  .resultColl {
    min-height: 300px;
    width: 95%;
    margin: 0 auto;
  }
  .resultTitleDivider {
    margin: 0 10px;
    background-color: #176645;
  }
  .resultTable {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    border: thin;
    table-layout: fixed;
    .tableRecord {
      height: 120px;
      border-bottom: 1px solid rgba(102, 102, 102, 0.2);
    }
    .tableProName {
      width: 100%;
      display: flex;
      flex-direction: row;
      .left {
        width: 30%;
        height: 100%;
        text-align: center;
        line-height: 120px;
        // background-color: black;
        overflow: hidden;
      }
      .right {
        width: 60%;
        height: 100%;
        text-align: left;
        vertical-align: center;
        .t1 {
          font-size: 14px;
          line-height: 30px;
          margin-top: 40px;
        }
        .t2 {
          font-size: 12px;
          line-height: 20px;
        }
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    th {
      background: #f6f6f6;
    }
    td {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .buyerInfo {
    font-size: 14px;
    padding-left: 15px;
    width: 100%;
    height: 35px;
    // background: #fafafa;
    line-height: 35px;
  }
  .el-divider__text {
    color: #666;
  }
  /deep/ .el-collapse-item__header {
    height: 50px;
    padding-left: 10px;
    background-color: #eaf6f0;
    font-size: 14px;
    &:hover {
      color: green;
    }
  }
  /deep/ .el-collapse-item {
    margin-bottom: 6px;
  }
}
/deep/ .vcodeDialog {
  width: 450px;
  .info1 {
    font-size: 22px;
    // text-align: center;
  }
  .info2 {
    // text-align: center;
    font-size: 14px;
    margin: 20px 0;
    color: #777;
  }
  .el-input {
    width: 44px;
  }
  .el-dialog__body {
    text-align: initial;
    padding: 25px 25px 0px 50px;
  }
  .vcodeBtn {
    border-radius: 30px;
  }
  .dialog-footer .el-button {
    background-color: #31b57e;
    border: 1px solid #619ca1;
    color: white;
  }
}
</style>
